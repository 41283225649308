@import url('bootstrap/dist/css/bootstrap.min.css');
@import url('@fortawesome/fontawesome-free/css/all.css');
@import url('react-datepicker/dist/react-datepicker.css');

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 400;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProBook.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 400;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProBookOblique.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 400;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProRoman.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: italic;
    font-weight: 400;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProOblique.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 300;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProLight.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: italic;
    font-weight: 300;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProLightOblique.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 500;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProMedium.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-weight: 500;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProMediumOblique.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 700;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProHeavy.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: italic;
    font-weight: 700;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProHeavyOblique.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: normal;
    font-weight: 750;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProBlack.otf')
            format('truetype');
}

@font-face {
    font-family: Avenir LT Pro;
    font-style: italic;
    font-weight: 750;
    src: local('Avenir LT Pro'),
        url('./assets/font/avenir-lt-pro-cufonfonts/AvenirLTProBlackOblique.otf')
            format('truetype');
}

body {
    margin: 0;
    font-family: Avenir LT Pro, 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --blue: #1e90ff;
    --white: #ffffff;
    --green: #297037;
    --chicory_green: #b9a978;
    --desktop-header-height: 100px;
    --mobile-header-height: 100px;
    --sidebar-width: 250px;
    --steel-blue-color: #718ebf;
    --midnight-blue: #0e1c44;
    --border-color: #e6eff5;
    --positive-color: #41d4a8;
    --negative-color: #fe5c73;
    --title-color: #161616;
}

::-webkit-scrollbar {
    width: 8px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--steel-blue-color);
    border-radius: 6px;
}

/* input:focus {
  border: 2px solid var(--chicory_green) !important;
  box-shadow: none !important
} */

textarea {
    font-family: Avenir LT Pro !important;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    color: black !important;
}

textarea:focus {
    border: 2px solid var(--chicory_green);
    box-shadow: none !important;
    border-color: var(--chicory_green) !important;
}

textarea.error {
    border-color: red !important;
}

textarea::placeholder {
    color: var(--steel-blue-color) !important;
}

ul {
    list-style: none;
}

.text-green {
    color: #297037;
}

.btn-protrade {
    background-color: var(--chicory_green);
    color: var(--title-color);
    border: none;
    width: 100%;
}

.btn-protrade:active,
.btn-protrade:hover {
    color: var(--title-color) !important;
    background-color: var(--chicory_green) !important;
}

.btn-protrade span {
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
}

.btn-label-icon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
}

.error {
    border: 1px solid red !important;
}

.error-select-box {
    border: 1px solid red !important;
    border-radius: 10px;
}

@keyframes fadeinbackdrop {
    100% {
        opacity: 0.6;
    }
}

/* common css */
.title-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title-section .main-title {
    font-family: Avenir LT Pro;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--title-color);
}

.small-title {
    font-family: Avenir LT Pro;
    font-size: 22px;
    font-weight: 750;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--title-color);
}

.positive-text {
    color: var(--positive-color);
    font-weight: 700;
}

.negative-text {
    color: var(--negative-color);
    font-weight: 700;
}

/* table css */
table thead th {
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--steel-blue-color) !important;
    border-bottom-color: var(--border-color) !important;
    text-align: center;
    padding: 5px 0 15px 0 !important;
}

table tbody tr td {
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--title-color) !important;
    border-bottom-color: var(--border-color) !important;
    padding: 15px 0 !important;
}

.input_label {
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--title-color);
    margin-bottom: 10px;
}

.btn-add-account:hover,
.btn-add-account:active,
.btn-save:hover,
.btn-save:active {
    background-color: var(--chicory_green) !important;
    /* border: none !important; */
    color: var(--title-color) !important;
    /* background-color: #cab287 !important;
  box-shadow: 0 4px 15px 0 #cab287; */
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
    background-color: var(--chicory_green) !important;
    border: none !important;
    color: var(--title-color) !important;
}

.btn-add-account,
.btn-save {
    /* margin-top: 10px; */
    background-color: var(--chicory_green);
    border: none;
    color: var(--title-color);
    font-family: Avenir LT Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 15px 70px;
    border-radius: 8px;
}

.btn-groups {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}

.btn-delete:hover {
    background: #fe5c73 !important;
    color: #fff !important;
}

.btn-delete {
    background: transparent !important;
    border: 1px solid #fe5c73 !important;
    /* margin-right: 10px !important; */
}

.btn-add-account {
    /* width: 100%; */
    margin-top: 10px;
    padding: 10px 20px;
}

/* custom check box */
.check-box-lable {
    margin-bottom: 25px !important;
}

.custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 16px;
    color: #333;
    transition: color 0.3s;
}

.custom-checkbox input[type='checkbox'] {
    display: none;
}

.custom-checkbox .checkmark {
    width: 24px;
    height: 24px;
    border: 2px solid #e6eff5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
    transform-style: preserve-3d;
}

.custom-checkbox .checkmark::before {
    content: '\2713';
    font-size: 16px;
    color: transparent;
    transition: color 0.3s, transform 0.3s;
}

.custom-checkbox input[type='checkbox']:checked + .checkmark {
    background-color: var(--chicory_green);
    border: none;
    transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.custom-checkbox input[type='checkbox']:checked + .checkmark::before {
    color: #fff;
}

.custom-checkbox:hover {
    color: #666;
}

.custom-checkbox:hover .checkmark {
    border-color: #e6eff5;
    background-color: #f0f0f0;
    transform: scale(1.05);
}

.custom-checkbox input[type='checkbox']:focus + .checkmark {
    box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
    outline: none;
}

.custom-checkbox .checkmark,
.custom-checkbox input[type='checkbox']:checked + .checkmark {
    transition: background-color 1.3s, border-color 1.3s, color 1.3s,
        transform 0.3s;
}

@keyframes jelly {
    from {
        transform: scale(1, 1);
    }

    30% {
        transform: scale(1.25, 0.75);
    }

    40% {
        transform: scale(0.75, 1.25);
    }

    50% {
        transform: scale(1.15, 0.85);
    }

    65% {
        transform: scale(0.95, 1.05);
    }

    75% {
        transform: scale(1.05, 0.95);
    }

    to {
        transform: scale(1, 1);
    }
}

.apexcharts-title-text {
    font-family: Avenir LT Pro !important;
    font-size: 24px;
    line-height: 30px;
}

.custom-apexcharts-yaxis-title {
    font-family: Avenir LT Pro !important;
    font-size: 16px;
    line-height: 20px;
}

.custom-apexcharts-xaxis-title {
    font-family: Avenir LT Pro !important;
    font-size: 16px;
    line-height: 20px;
}

.apx-legend-position-top {
    top: 0 !important;
}

.hidden-xs-up {
    display: none !important;
}

/* Modal css */
.fade.modal-backdrop.show {
    z-index: 9999;
}

.fade.modal.show {
    z-index: 99999;
}

.modal-header {
    justify-content: space-between;
}

.modal-header .close {
    border: 1px solid #e6eff5;
    background: transparent;
    border-radius: 8px;
}

/* sweetAlert css */
.swal-button--confirm:hover,
.swal-button--confirm {
    background-color: var(--chicory_green) !important;
    border: none !important;
    color: var(--title-color) !important;
    font-family: Avenir LT Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 8px;
}

/*  */
input::-ms-reveal,
input::-ms-clear {
    display: none;
}

@media (max-width: 999px) {
    .title-section {
        justify-content: flex-start;
        flex-direction: column-reverse;
        align-items: baseline;
        gap: 15px;
    }

    .title-section .main-title {
        /* display: none; */
        font-size: 22px;
        font-weight: 750;
        line-height: 26px;
        letter-spacing: 0em;
    }

    .apx-legend-position-top {
        top: 45px !important;
    }
}

@media (max-width: 565px) {
    .apexcharts-title-text {
        font-size: 14px;
        line-height: 20px;
    }
}

/* lending page */
.heading-6 {
    color: #080f25;
    text-align: left;
    padding-right: 10px;
    font-family: Montserrat, sans-serif;
    font-size: 62px;
    font-weight: 600;
    line-height: 1.3em;
}

.heading-9 {
    font-size: 60px;
    color: #080f25;
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 0;
    line-height: 1.4em;
    font-weight: 400;
}

.heading-title {
    color: #080f25;
    font-size: 36px;
    font-weight: 600;
    line-height: 1.571em;
}

.sub-title {
    margin-bottom: 0px;
    font-size: 20px;
}

.section-wrap {
    padding: 70px 0;
}

.text-green {
    color: #11845b;
}

.dropdown-item.active {
    background-color: #b9a978 !important;
}

@media (max-width: 565px) {
    .heading-6 {
        font-size: 45px;
    }

    .heading-9 {
        font-size: 55px;
    }
}
