.search-wrap {
    position: relative;
}

.input-group-prepend {
    position: absolute;
    right: 17px;
    /* z-index: 99; */
    top: 12px;
}

.search-input::placeholder {
    font-family: Avenir LT Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    color: #8BA3CB;
}

.search-input {
    width: 100%;
    height: 50px;
    border-radius: 20px;
    padding-right: 45px;
    font-family: Avenir LT Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    /* color: #8BA3CB; */
    box-shadow: 4px 4px 18px -5px #E7E4E8CC;

}

.search-input:focus {
    border: 2px solid var(--chicory_green) !important;
    box-shadow: none !important
}