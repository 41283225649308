.auth-layout {
    overflow-x: hidden;
    background-color: #F5F7FA;
}

.auth-layout-image {
    max-width: 100%;
    height: 100vh;
}

.auth-layout-right-panel {
    position: relative;
    background-image: url('../../assets/images/img-background-protradeplus.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: top;
    height: 100vh;

}

.auth-gradient {
    height: 100vh;
    width: 100%;
    background: linear-gradient(180deg, rgba(19, 33, 60, 0) 0%, rgba(19, 33, 60, 0.963938) 85.69%, #13213C 87.14%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.auth-layout-right-panel .logo-section img {
    max-width: 100%;
}

.auth-layout-right-panel .logo-section {
    text-align: center;
    padding-top: 10em;
}

.auth-info-section {
    color: #fff;
    background: linear-gradient(180deg, rgba(19, 33, 60, 0) 0%, rgba(19, 33, 60, 0.963938) 85.69%, #13213C 87.14%);
    height: 180px;
    width: 100%;
    text-align: center;
}

.auth-info-section h3 {
    font-family: Avenir LT Pro;
    font-size: 36px;
    font-weight: 750;
    line-height: 52px;
    letter-spacing: 0px;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
}

.auth-info-section p {
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #F5F7FA;
    font-style: normal;
}

.auth-footer {
    position: unset;
    bottom: 0;
    width: -webkit-fill-available;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 35px;
    padding-top: 0;
    margin-top: 20px;
}

.auth-footer span {
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #878787;
    font-style: normal;

}

.auth-privacy {
    margin-left: 45px;
}

.auth-common {
    padding: 35px;
    height: 100vh;
    overflow: auto;
}

.auth-footor-privecy-section span {
    cursor: pointer;
}

@media (max-width:1200px) {
    .auth-layout-right-panel {
        display: none;
    }
}

@media (max-width:999px) {
    .auth-common {
        padding: 10px;
    }

    .auth-layout {
        height: 100vh;
    }

    .auth-layout-right-panel {
        display: none;
    }

    .auth-footer {
        position: unset;
        width: auto;
        padding: 0;
        margin-bottom: 15px;
    }
}

@media (max-width:768px) {
    .auth-footer {
        flex-direction: column;
        gap: 15px;
    }
}