.lable-wrap {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--title-color);
}

.custom-date-picker-input::placeholder {
    color: var(--steel-blue-color);
}

.custom-date-picker-icon {
    position: absolute;
    right: 0px;
    width: 20px;
    height: 20px;
    top: 8px;
}

.custom-date-picker-input {
    font-family: Avenir LT Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: black;
    height: 50px;
    padding: 6px 25px 5px 10px !important;
}

.custom-popper-date-picker {
    z-index: 999;
}

.react-datepicker {
    padding: 0 10px;
    border-radius: 12px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
}

.react-datepicker__day-names {
    border-top: 1px solid #f4f4f5;
}

.react-datepicker__current-month {
    margin-bottom: 22px;
    margin-top: 15px;
}

.react-datepicker__navigation {
    margin-top: 15px;
}

.react-datepicker__navigation {
    box-shadow: 0px 1px 5px -1px #110c221a;
    border: 1px solid #f4f4f5;
    /* border-image-source: linear-gradient(180deg, rgba(228, 230, 231, 0.6) 0%, #E4E4E7 100%); */
}

.react-datepicker__navigation-icon--previous::before {
    right: -4px;
}

.react-datepicker__navigation-icon--next::before {
    left: -4px;
}

.react-datepicker__navigation-icon::before {
    border-color: #3f3f46;
}

.react-datepicker__navigation--previous {
    left: 10px;
}

.react-datepicker__navigation--next {
    right: 10px;
}

.react-datepicker__month-container {
    background-color: #fff;
    border-radius: 12px;
    font-family: Avenir LT Pro;
}

.react-datepicker__day {
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    padding: 2px 5px;
}

.react-datepicker__day--outside-month {
    visibility: hidden;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover,
.react-datepicker__quarter-text--keyboard-selected:hover,
.react-datepicker__year-text--keyboard-selected:hover {
    background-color: var(--chicory_green);
    border-radius: 16px;
    font-weight: 750;
}

.react-datepicker__day--keyboard-selected {
    background-color: #b9a9786b;
    border-radius: 16px;
}
