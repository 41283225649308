.page-not-found-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70vh;
    gap: 100px;
}

.page-not-found-wrap .page-not-found-content {
    text-align: center;
}

.page-not-found-wrap .page-not-found-content h2 {
    font-family: Avenir LT Pro;
    font-size: 48px;
    font-weight: 750;
    line-height: 58px;
    letter-spacing: 0em;
    text-align: center;
    color: #263238;
}

.page-not-found-wrap .page-not-found-content p {
    font-family: Avenir LT Pro;
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    color: #000000;
    margin: 0;
}

.page-not-found-wrap .page-not-found-content button {
    font-family: Avenir LT Pro;
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    background-color: var(--chicory_green);
    border: none;
    color: #161616;
    padding: 15px 30px;
    border-radius: 50px;
    margin-top: 30px;
}

.not-found-icon svg {
    width: 100%;
    height: 100%;
}

@media (max-width:999px) {
    .page-not-found-wrap {
        flex-wrap: wrap;
        height: auto;
        gap: 30px;
    }

    .page-not-found-wrap .page-not-found-content h2 {
        font-family: Avenir LT Pro;
        font-size: 32px;
        font-weight: 750;
        line-height: 38px;
    }

    .page-not-found-wrap .page-not-found-content p {
        font-family: Avenir LT Pro;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
    }

    .page-not-found-wrap .page-not-found-content button {
        margin-bottom: 50px;
    }
}