.nav-bar-header {
    background: #fff;
    transition: 0.5s ease-in-out;
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
}

.navbar-manu-wrap {
    margin-left: 100px;
}

.navbar-expand-xl .navbar-nav {
    gap: 35px;
}

.nav-link {
    color: #1a1b1f !important;
}

.nav-link:hover {
    color: rgba(26, 27, 31, 0.75) !important;
}

.nav-link.nav-login-link {
    font-weight: 700;
}

.nav-link.nav-login-link:hover {
    color: rgba(26, 27, 31, 0.75) !important;
}

.navbar {
    border: none;
}

.navbar-logo.navbar {
    background: transparent;
}

.nav-group-login {
    padding-left: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}
/* .nav-group-login .nav-link {
    margin-right: 25px;
} */

.navbar-manu-wrap {
    margin-left: auto;
}

.navbar-logo img {
    width: 220px;
}

.btn-get-started-black,
.btn-get-started {
    color: #000;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #b9a978;
    padding: 12px 25px;
    font-size: 12px;
    line-height: 20px;
    transition: all 0.2s;
    border-radius: 0;
    border: none;
}

.btn-get-started-black {
    background-color: #000;
    color: #fff !important;
}

.btn-get-started-black:hover,
.btn-get-started:hover {
    color: #fff !important;
    background-color: #32343a !important;
}

.btn-bg-black {
    color: #fff;
    letter-spacing: 2px;
    text-transform: uppercase;
    background-color: #000;
    padding: 12px 25px;
    font-size: 12px;
    line-height: 20px;
    transition: all 0.2s;
    border-radius: 0;
    border: none;
}

.btn-bg-black:hover {
    color: #fff !important;
    background-color: #32343a !important;
}

footer {
    background-color: #0e1c44;
}

.footer-wrap {
    padding: 50px 30px 40px;
    color: #fff;
}

.footer-link {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    flex-wrap: wrap;
}

.footer-link a {
    text-decoration: none;
    color: #fff;
}

.footer-social-block-three {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 30px;
    flex-wrap: wrap;
}

.footer-second-section {
    padding: 35px 0;
}

.terms-policy-div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.term-wrap-div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
}

.term-wrap-div a {
    color: #fff;
}

.footer-social-block-three i {
    font-size: 25px;
    color: white;
}
.landing-page-nav .nav-link.active {
    color: #b9a978 !important;
}

.dropdown-menu[data-bs-popper] {
    top: 83%;
}

@media (max-width: 1200px) {
    .navbar-manu-wrap {
        margin-left: unset;
        margin-top: 20px;
        box-shadow: 0 8px 50px rgba(0, 0, 0, 0.05);
        padding: 20px;
        background: white;
        overflow: auto;
        max-height: 80vh;
    }
    .dropdown-nav-link {
        padding: 0 15px;
    }
    .nav-group-login {
        flex-direction: column;
    }
}

@media (max-width: 1000px) {
    .navbar-manu-wrap {
        margin-left: 0;
    }

    .footer-social-block-three {
        justify-content: center;
        margin-top: 20px;
    }

    .terms-policy-div {
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media (max-width: 450px) {
    .navbar {
        height: 70px !important;
    }

    .navbar-logo {
        padding: 0;
    }

    .navbar-logo img {
        width: 150px;
    }
}
