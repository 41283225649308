.main-layout-wrap {
    position: relative;
}

.navbar {
    background-color: #fff;
    /* position: relative; */
    border: 1.5px solid #e6eff5;
    justify-content: space-between;
    height: var(--desktop-header-height);
}

.navbar-nav.navbar-right {
    padding-right: 5px;
    margin-left: 15px;
}

.navbar-nav.navbar-right li {
    cursor: pointer;
}

.user-name {
    font-family: Avenir LT Pro;
    font-size: 20px;
    font-weight: 750;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    color: #161616;
}
.other-user-name {
    font-family: Avenir LT Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--steel-blue-color);
    text-align: center;
    display: block;
}

.profile-image {
    width: 60px;
    height: 60px;
}

.profile-image img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
}

.main-sidebar {
    background-color: #fff;
    width: 250px;
    height: 100%;
    position: fixed;
    top: 0;
    z-index: 9999;
    border-right: 1px solid #e6eff5;
    overflow-y: auto;
    overflow-x: hidden;
    display: none;
}

.sidebar-brand {
    display: inline-block;
    width: 100%;
    text-align: center;
    height: 100px;
    line-height: 100px;
    position: sticky;
    top: 0;
    z-index: 99999999;
    background: white;
    border-bottom: 1px solid #e6eff5;
}

.main-content {
    background: #f5f7fa;
    min-height: calc(100vh - var(--desktop-header-height) + 40px);
    /* padding-left: 280px; */
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 120px;
    width: 100%;
    position: relative;
}

.main-footer {
    padding: 20px 30px;
    color: #98a6ad;
    border-top: 1px solid #e3eaef;
    display: flex;
    background: #ffffff;
    font-weight: 600;
    text-transform: uppercase;
    font-size: 11px;
    width: 100%;
    transition: all 0.5s;
    align-items: center;
    justify-content: space-between;
}

.main-footer span {
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: #b1b1b1;
    text-transform: capitalize;
}

.footer-right {
    float: right;
}

.privacy-text {
    margin-left: 50px;
}

.hamburgeIcon {
    display: none;
}

.back-mobile-icon {
    display: none;
}

.sidebar-menu {
    padding: 0;
    margin: 0;
    margin-top: 20px;
}

.dropdown {
    display: block;
    cursor: pointer;
}

.dropdown.active .nav-link {
    color: var(--green);
}

.dropdown .nav-link {
    position: relative;
    display: flex;
    align-items: center;
    height: 60px;
    padding: 0 20px;
    width: 100%;
    letter-spacing: 0.3px;
    color: #60686f;
    font-weight: 600;
    text-decoration: none;
    margin-left: 10px;
    /* gap: 10px; */
}

.dropdown.active .nav-link span {
    color: var(--green);
    font-weight: 700;
}

.dropdown .nav-link span {
    font-family: Avenir LT Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #b1b1b1;
}

.dropdown svg {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    text-align: center;
    fill: rgba(75, 75, 90, 0.12);
}

li.dropdown:hover span {
    color: var(--green);
}

li.dropdown:hover svg path {
    fill: var(--green);
}

.dropdown.Logout {
    display: none;
}

.mobile-title {
    display: none;
}
.logo-section {
    display: block;
    margin-left: 5px;
}
.mobile-hidden-menu {
    display: flex;
    margin-bottom: 0;
}
@media (max-width: 999px) {
    .logo-section {
        display: none;
    }
    .mobile-hidden-menu {
        display: none;
    }
    .main-content {
        padding-left: 20px;
        padding-top: 100px;
        min-height: calc(100vh - var(--mobile-header-height));
    }

    .main-layout-wrap.show-backdrop::before {
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0;
        z-index: 9999;
        animation-name: fadeinbackdrop;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }

    .navbar {
        justify-content: space-between;
        padding: 0px 20px;
        height: var(--mobile-header-height);
        align-items: center;
    }

    .mobile-title {
        width: 50%;
        display: inline-block;
        font-family: Avenir LT Pro;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: center;
        color: #161616;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .user-name,
    .other-user-name,
    .logout-button {
        display: none;
    }

    .profile-image {
        width: 35px;
        height: 35px;
    }

    .main-footer {
        padding-left: 30px;
        flex-direction: column;
        gap: 15px;
    }

    .hamburgeIcon {
        display: block;
    }

    .main-sidebar.mobile-sidebar {
        display: block;
        left: -300px;
    }

    .main-sidebar.mobile-sidebar.show-mobile {
        display: block;
        transition: left 0.3s ease;
        left: 0;
    }

    .back-mobile-icon {
        display: inline-block;
    }

    .navbar-nav.navbar-right {
        padding-right: 0px;
    }

    .dropdown.Logout {
        display: block;
        position: fixed;
        bottom: 0;
        width: max-content;
        border-top: 1px solid #e6eff5;
        width: var(--sidebar-width);
        background-color: var(--white);
    }

    .dropdown.Logout .nav-link span {
        color: #fe5c73;
    }

    .sidebar-menu li:nth-last-child(2) {
        margin-bottom: 70px;
    }

    li.dropdown.active::before {
        content: '';
        width: 6px;
        height: 60px;
        display: block;
        background: var(--green);
        position: absolute;
        border-radius: 0px 10px 10px 0px;
    }
    .dropdown .nav-link {
        gap: 10px;
    }
}
