.lable-wrap {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 8px;
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    color: var(--title-color);

}

.input-box {
    height: 50px;
    font-family: Avenir LT Pro;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    color: black;
}

.input-box:focus {
    border: 2px solid var(--chicory_green);
    box-shadow: none !important
}

.input-box::placeholder {
    color: var(--steel-blue-color);
}