thead {
    position: sticky;
    top: 0;
    z-index: 2;
    cursor: pointer;
}

.sort-icon {
    margin-left: 6px;
}

.full-width {
    width: auto;
    margin: 0 auto;
}
.mv-text {
    width: max-content;
    font-size: 14px;
    margin: auto;
}

@media (max-width: 1300px) {
    .full-width {
        padding: 0 15px;
    }
}