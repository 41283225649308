.navigate-auth-link {
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: end;
    color: #080f25;
}

.navigate-auth-link p b {
    color: #161616;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
}

.auth-form-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    height: 85vh;
    margin-top: 30px;
    justify-content: center;
}

.auth-title {
    font-family: Avenir LT Pro;
    font-size: 40px;
    font-weight: 750;
    line-height: 44px;
    letter-spacing: 0px;
    text-align: center;
    color: #212121;
    text-transform: uppercase;
}

.auth-sub-title {
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: center;
    color: #424242;
    font-style: normal;
    text-transform: uppercase;
}

.input_container {
    max-width: 420px;
    width: 100%;
    height: fit-content;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.icon {
    position: absolute;
    z-index: 99;
    left: 12px;
    bottom: 24px;
}

.icon-rigth {
    position: absolute;
    right: 15px;
    bottom: 24px;
    cursor: pointer;
}

.input_field {
    width: 420px;
    height: 72px;
    padding: 0 0 0 40px;
    border-radius: 7px;
    outline: none;
    border: 1px solid #0000001a;
    transition: all 0.3s cubic-bezier(0.15, 0.83, 0.66, 1);
    font-family: Avenir LT Pro;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0px;
    text-align: left;
}

.input_field:focus {
    border: 2px solid #b9a978;
    box-shadow: none !important;
}

.forgot-password-text {
    text-align: center;
    margin-top: 25px;
}

.forgot-password-text span {
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    font-style: unset;
    color: #000000;
    cursor: pointer;
}

.separator span {
    font-family: Avenir LT Pro;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0px;
    text-align: left;
    color: #616161;
}

.separator {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    color: #e0e0e0;
}

.separator .line {
    display: block;
    width: 100%;
    height: 2px;
    border: 0;
    background-color: #b2b2b2;
}

.google-login-button {
    border-radius: 8px;
    box-sizing: border-box;
    padding: 15px;
    box-shadow: 4px 4px 18px -5px #dadde833;
    background-color: #ffffff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    font-style: unset;
    gap: 5px;
}

.other-info-reset-password {
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    color: #424242;
}

.reset-btn {
    max-width: 420px;
}

.reset-info {
    max-width: 520px;
    width: 100%;
}

.subscription-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.subscription-section {
    background-color: var(--white);
    height: 525px;
    width: 100%;
    border-radius: 25px;
    position: relative;
    padding: 30px;
    max-width: 300px;
    margin-right: 15px;
}

.btn-subscription {
    position: absolute;
    width: auto;
    bottom: 30px;
    left: 30px;
    right: 30px;
    width: -moz-available;
}

.btn-subscription span {
    font-family: Avenir LT Pro;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: 0px;
}

.custom-stripe-btn .StripeCheckout {
    position: absolute;
    width: auto;
    bottom: 30px;
    left: 30px;
    right: 30px;
    right: 30px;
    height: 45px;
    background: #b9a978 !important;
    width: -moz-available;
}

.custom-stripe-btn .StripeCheckout span {
    font-family: Avenir LT Pro !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    line-height: 18px !important;
    letter-spacing: 0px !important;
    background: #b9a978 !important;
    box-shadow: none !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    color: var(--title-color) !important;
    text-shadow: none !important;
}

.price-section .price-lable {
    font-family: Avenir LT Pro;
    font-size: 30px;
    font-weight: 750;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    text-transform: capitalize;
}

.amount-wrap h2 {
    font-family: Avenir LT Pro;
    font-size: 50px;
    font-weight: 750;
    line-height: 60px;
    letter-spacing: 0em;
    margin-top: 10px;
}

.amount-wrap sub {
    color: #616163;
    font-family: Avenir LT Pro;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
}

.description-info ul,
.subscription-info-wrap {
    margin-top: 30px;
}

.description-info ul li,
.subscription-info-wrap .subscription-info {
    font-family: Avenir LT Pro;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    color: #616163;
    margin-bottom: 15px;
}

.description-info strong {
    font-weight: 750;
    color: #080f25;
}

.description-info ul {
    list-style: none;
    padding-left: 0;
    position: relative;
}

.description-info ul li {
    padding-left: 1.5em;
    margin-bottom: 0.5em;
}

.description-info ul li::before {
    content: '';
    position: absolute;
    left: 0;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-image: url('../../assets/icon/rightIcon.svg');
    margin-top: 3px;
}

.error-message {
    max-width: 420px;
    display: block;
}
.success h1 {
    color: #88b04b;
    font-family: 'Nunito Sans', 'Helvetica Neue', sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
}
.cancel h1 {
    color: #ff0000ad;
}
.success p {
    color: #404f5e;
    font-family: 'Nunito Sans', 'Helvetica Neue', sans-serif;
    font-size: 20px;
    margin: 0;
}
.success i {
    color: #9abc66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}
.cancel i {
    color: #ff0000ad;
}
.success.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #c8d0d8;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
}

@media (max-width: 999px) {
    .navigate-auth-link {
        text-align: center;
        margin-top: 15px;
    }

    /* .auth-form-section {
        height: auto;
    } */

    .auth-form-section.subscription-div {
        height: auto;
    }

    .other-info-reset-password {
        margin-bottom: 50px;
    }

    .subscription-wrap {
        flex-wrap: wrap;
        flex-direction: row;
    }

    .subscription-section {
        margin-right: 15px;
        margin-bottom: 15px;
    }
}

@media (max-width: 768px) {
    .auth-title {
        font-family: Avenir LT Pro;
        font-size: 22px;
        font-weight: 750;
        line-height: 44px;
        letter-spacing: 0px;
        text-align: center;
    }

    .auth-sub-title {
        font-family: Avenir LT Pro;
        font-size: 14px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0px;
        text-align: center;
    }
}

@media only screen and (max-device-width: 999px) and (orientation: landscape) {
    .auth-form-section {
        height: auto;
    }
}

@media (max-width: 620px) {
    .subscription-section {
        margin-right: 0;
    }
}

@media (max-width: 450px) {
    .input_container {
        width: 325px;
    }

    .reset-btn {
        width: 325px;
    }

    .input_field {
        width: 325px;
    }

    .error-message {
        max-width: 320px;
        display: block;
    }
}

/* Target iPhone 6 in portrait mode */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    /* Your CSS rules for iPhone 6 in portrait mode */
    .auth-form-section {
        height: 100%;
        margin-top: 110px;
        margin-bottom: 100px;
    }

    .auth-form-section.subscription-div {
        margin-top: 30px;
    }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    /* Your CSS rules for iPhone 5 and iPhone SE in portrait mode */
    .auth-form-section {
        height: 100%;
        margin-top: 140px;
        margin-bottom: 100px;
    }

    .auth-form-section.subscription-div {
        margin-top: 30px;
    }
}

@media (max-width: 320px) {
    .input_container {
        width: 290px;
    }

    .input_field {
        width: 290px;
    }

    .error-message {
        max-width: 290px;
        display: block;
    }
}
