.rising-tides-section {
    background-image: url('../../assets/images/bg-image.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    margin-top: -110px;
    padding-top: 220px;
    padding-bottom: 220px;
}

.home-page-content {
    margin-bottom: 20px;
}

.home-page-content p {
    margin: 0;
}

.with-protrade p {
    font-size: 28px;
}
.home-stock-table tbody td {
    padding: 11px 10px !important;
}
.home-stock-table tbody td.stock-name-text {
    text-transform: uppercase;
    text-align: left;
}
.long-term-section {
    position: relative;
}

.long-term-section-wrap {
    background-color: #fff;
    border-radius: 20px;
    padding: 50px 20px;
    position: relative;
    width: 100%;
    top: -70px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.long-term-wrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
}

.long-term-wrap img {
    width: 80px;
    height: 80px;
    max-width: 100%;
}

.why-protradePlus-section {
    padding: 15px 0;
}

.why-protradePlus-lits {
    padding: 0 15px;
    margin: 0;
}

.why-protradePlus-lits li {
    padding: 10px;
    list-style-type: initial;
}

.why-protrade-svg-icon {
    max-width: 100%;
    height: 100%;
}

.esay-process-wrap {
    padding: 40px 20px;
    box-shadow: -4px 7px 17px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: baseline;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
    border-radius: 20px;
}

.esay-process-wrap h3 {
    font-weight: 400;
}

.esay-process-wrap img {
    width: 114px;
    max-width: 100%;
}

.financial-section {
    background-color: #b9a978;
}

.title-section-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.transparent-title-section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
}

/* timeLine */

.timeline {
    position: relative;
    margin: 50px auto;
    padding: 40px 0;
    width: 100%;
    box-sizing: border-box;
}

.timeline:before {
    content: '';
    position: absolute;
    left: 50%;
    width: 2px;
    height: 100%;
    background: #c5c5c5;
}

.timeline ul {
    padding: 0;
    margin: 0;
}

.timeline ul li {
    list-style: none;
    position: relative;
    width: 50%;
    padding: 20px 40px;
    box-sizing: border-box;
}

.timeline ul li:nth-child(odd) {
    float: left;
    text-align: right;
    clear: both;
}

.timeline ul li:nth-child(even) {
    float: right;
    text-align: left;
    clear: both;
}

.content {
    padding-bottom: 20px;
}

.timeline ul li:nth-child(odd):before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 24px;
    right: -6px;
    background: rgb(59 124 72);
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgb(60 60 60 / 31%);
}

.timeline ul li:nth-child(even):before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 24px;
    left: -4px;
    background: rgb(59 124 72);
    border-radius: 50%;
    box-shadow: 0 0 0 3px rgb(60 60 60 / 31%);
}

.timeline ul li h3 {
    padding: 0;
    margin: 0;
    color: rgb(59 124 72);
    font-weight: 600;
}

.timeline ul li p {
    margin: 10px 0 0;
    padding: 0;
}

.timeline ul li .time h4 {
    margin: 0;
    padding: 0;
    font-size: 14px;
}

.timeline ul li:nth-child(odd) .time {
    position: absolute;
    top: 12px;
    right: -165px;
    margin: 0;
    padding: 8px 16px;
    background: rgba(233, 33, 99, 1);
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.3);
}

.timeline ul li:nth-child(even) .time {
    position: absolute;
    top: 12px;
    left: -165px;
    margin: 0;
    padding: 8px 16px;
    background: rgba(233, 33, 99, 1);
    color: #fff;
    border-radius: 18px;
    box-shadow: 0 0 0 3px rgba(233, 33, 99, 0.3);
}

/*  */

.subscription-section.landing-subscription {
    box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
}

/* skeleton-table-loader  */

@keyframes skeleton-loading {
    40% {
        background-position: 100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.skeleton-table-loader .loading {
    position: relative;
}
.skeleton-table-loader .loading .bar {
    background-color: #e7e7e7;
    height: 24px;
    border-radius: 7px;
    width: 100%;
}
.skeleton-table-loader .loading:after {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 24px;
    background-image: linear-gradient(
        100deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 100%,
        rgba(255, 255, 255, 0) 80%
    );
    background-size: 200px 24px;
    background-position: -100px 0;
    background-repeat: no-repeat;
    animation: skeleton-loading 1s infinite;
}

.section-btn-group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    flex-wrap: wrap;
    margin-top: 20px;
}

@media (max-width: 1000px) {
    .timeline {
        width: 100%;
    }
}

@media (max-width: 767px) {
    .timeline {
        width: 100%;
        padding-bottom: 0;
    }

    .timeline h1 {
        font-size: 40px;
        text-align: center;
    }

    .timeline:before {
        left: 20px;
        height: 100%;
    }

    .timeline ul li:nth-child(odd),
    .timeline ul li:nth-child(even) {
        width: 100%;
        text-align: left;
        padding-left: 50px;
        padding-bottom: 50px;
    }

    .timeline ul li:nth-child(odd):before,
    .timeline ul li:nth-child(even):before {
        top: -18px;
        left: 16px;
    }

    .timeline ul li:nth-child(odd) .time,
    .timeline ul li:nth-child(even) .time {
        top: -30px;
        left: 50px;
        right: inherit;
    }
    .home-stock-table tbody td {
        padding: 11px 5px !important;
    }
}

@media (max-width: 1000px) {
    .stock-table-show {
        margin-top: 20px;
    }

    .long-term-wrap {
        gap: 15px;
        margin-bottom: 15px;
    }

    .why-protrade-svg-icon {
        margin-top: 20px;
        width: 100%;
    }

    .esay-process-Investing-section {
        gap: 35px;
    }

    .esay-process-wrap {
        align-items: center;
    }
}
