.change-password-wrap {
    padding: 100px;
}

.change_pwd_icon {
    bottom: 14px;
}

.change_pwd_icon-rigth {
    bottom: 14px;
}

.change-pwd-btn:hover,
.change-pwd-btn:active {
    background-color: var(--chicory_green) !important;
    border: none !important;
    color: var(--title-color) !important;
}

.change-pwd-btn {
    background-color: var(--chicory_green);
    border: none;
    color: var(--title-color);
    font-family: Avenir LT Pro;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    padding: 15px 20px;
    border-radius: 8px;
}

.input-with-icon-wrap {
    position: relative;
}

.input-with-icon-wrap .change_pwd_input {
    height: 50px;
    width: 100%;
    padding: 0 40px;
}

.changes-pwd-icon {
    max-width: 100%;
}

.change_pwd_input:focus {
    border: 2px solid var(--chicory_green) ;
    box-shadow: none !important
}

@media (max-width: 1200px) {
    .changes-pwd-form {
        margin-top: 50px;
    }

    .changes-pwd-icon {
        height: 100%;
        margin: 0 auto;
        text-align: center;
        display: block;
    }
}

@media (max-width: 999px) {
    .change-password-wrap {
        padding: 15px;
    }
}